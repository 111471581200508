import React, { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DotLoader from "react-spinners/DotLoader";

const Result = () => {
  return (
    <p className="success-message">Admission done. We will contact you soon.</p>
  );
};

function AdmissionForm({ props, formStyle }) {
  const [result, showresult] = useState(false);
  let [loading, setLoading] = useState(false);
  let [show, setShow] = useState(true);

  const [selectedCourseOption, setSelectedCourseOption] = useState("cadet"); // Default selected option
  const [courseTypeOption, setCourseTypeOption] = useState("regular"); // Default selected option
  const [admission, setAdmission] = useState({
    fullName: "",
    mobile: "",
    email: "",
    course: selectedCourseOption,
    courseType: courseTypeOption,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setAdmission((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleCourseSelectChange = (event) => {
    setSelectedCourseOption(event.target.value);
  };

  const handleCourseTypeSelectChange = (event) => {
    setCourseTypeOption(event.target.value);
  };

  const postAdmission = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShow(false);

    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/admissions`,
        admission,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(res.data.message, {
        className: "success-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setLoading(false);
      setShow(true);
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message, {
          className: "error-toast",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error(error.response.data.data.message, {
          className: "error-toast",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
      setLoading(false);
      setShow(true);
    }
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={postAdmission}>
      <div className="form-group">
        <label htmlFor="fullName">Full Name</label>

        <input
          type="text"
          name="fullName"
          id="fullName"
          placeholder="Full Name"
          required
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="mobile">Mobile</label>

        <input
          type="text"
          name="mobile"
          id="mobile"
          placeholder="Phone Number"
          minLength={11}
          maxLength={11}
          required
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email</label>

        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@email.com"
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="course">Course</label>

        <select
          name="course"
          id="course"
          required
          onChange={handleCourseSelectChange}
        >
          <option value="" disabled selected>
            Select a Course
          </option>
          <option value="cadet">Cadet College Admission</option>
          <option value="issb">ISSB (Written/ Preliminary/ Viva)</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="courseType">Course Type</label>

        <select
          name="courseType"
          id="courseType"
          required
          onChange={handleCourseTypeSelectChange}
        >
          <option value="" disabled selected>
            Select Course Type
          </option>
          <option value="regular">Regular</option>
          <option value="crash">Crash</option>
        </select>
      </div>

      <div className="form-group">
        {show ? (
          <button className="btn-default btn-large">Apply</button>
        ) : (
          <DotLoader color="#ff50cc" loading={loading} size={50} />
        )}
      </div>
      {/* 
            <div className="form-group">
                {result ? <Result /> : null}
            </div>  */}
    </form>
  );
}
export default AdmissionForm;
