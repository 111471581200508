import React, { useState, useEffect } from "react";
import SucessHistoryList from "./SucessHistoryList";
import { FaSpinner } from "react-icons/fa";
import Axios from "axios";

const SuccessHistoryProp = ({ column, StyleVarProp }) => {
  const [allSuccessHistoryList, setAllSuccessHistoryList] = useState([]);

  const getAllSuccessHistories = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSuccessHistory = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_API_URL}/success-histories`,
        {
          signal,
        }
      );
      if (res.status === 404) {
        setAllSuccessHistoryList([]);
      } else {
        setAllSuccessHistoryList(res.data.data);
      }
    };
    getSuccessHistory();
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    getAllSuccessHistories();
  }, []);

  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {allSuccessHistoryList &&
            allSuccessHistoryList.length > 0 &&
            allSuccessHistoryList.map((item) => (
              <div key={item.id} className={column}>
                <SucessHistoryList StyleVar={StyleVarProp} data={item} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SuccessHistoryProp;
