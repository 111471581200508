import React, { useState, useEffect, useRef } from "react";
import { FiHeadphones, FiMail, FiMapPin, FiPhoneCall } from "react-icons/fi";
import { Overlay, Popover } from "react-bootstrap";
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import ApiService from "../../apiService/apiservice";

const ContactOne = () => {
  const [contacts, setContacts] = useState({
    phoneNumbers: [],
    email: "",
    locations: [],
    latitude: 0,
    longitude: 0,
  });

  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    ApiService.get(`/contacts`)
      .then((res) => {
        if (res.data && Array.isArray(res.data)) {
          // Map over all contact entries
          const allContacts = res.data.map((entry) => {
            const {
              email = [],
              phoneNumbers = [],
              type = "Unknown Branch",
              address = "No Address Available",
              location = "#",
            } = entry;

            // Build a single contact object with locations
            return {
              emails: Array.isArray(email) ? email : [email], // Ensure email is an array
              phoneNumbers: Array.isArray(phoneNumbers)
                ? phoneNumbers
                : [phoneNumbers], // Ensure phoneNumbers is an array
              locations: [
                {
                  type,
                  address,
                  emails: email.join(", "), // Join email as a string
                  phoneNumbers: phoneNumbers.join(", "), // Join phone numbers as a string
                  link: location,
                },
              ],
            };
          });

          // Merge all contacts into a single object
          const mergedContacts = allContacts.reduce(
            (acc, contact) => {
              // Merge emails
              acc.emails = [...new Set([...acc.emails, ...contact.emails])]; // Avoid duplicates

              // Merge phone numbers with type info
              acc.phoneNumbers.push(
                ...contact.locations.map(
                  (loc) => `${loc.type} - ${loc.phoneNumbers}`
                )
              );

              // Merge all locations
              acc.locations.push(...contact.locations);

              return acc;
            },
            { emails: [], phoneNumbers: [], locations: [] } // Initial accumulator object
          );

          setContacts(mergedContacts); // Update state
        } else {
          console.error("Unexpected API response structure:", res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact data:", error);
      });
  }, []);

  const popoverStyle = {
    boxShadow: "0 10px 25px rgba(0,0,0,0.1)",
    border: "none",
    maxWidth: "300px",
    animation: "fadeIn 0.3s ease-in-out",
  };

  const locationItemStyle = {
    padding: "12px",
    borderBottom: "1px solid #eee",
    transition: "all 0.3s ease",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  };

  return (
    <>
      <div className="row row--15 contact-info-container">
        {/* Contact Info Sections */}
        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiPhoneCall />
            </div>
            <div className="inner">
              <h4 className="title">Phone Numbers</h4>
              {contacts.locations.length > 0 ? (
                contacts.locations.map((location, index) => (
                  <div key={index} style={{ marginBottom: "1rem" }}>
                    <p>
                      <strong>{location.type}:</strong> {location.phoneNumbers}
                    </p>
                  </div>
                ))
              ) : (
                <p>No Phone Numbers Available</p>
              )}
            </div>
          </div>
        </div>

        {/* <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiMail />
            </div>
            <div className="inner">
              <h4 className="title">Email Address</h4>
              {contacts.locations.length > 0 ? (
                contacts.locations.map((location, index) => (
                  <div key={index} style={{ marginBottom: "1rem" }}>
                    <p>
                      <strong>{location.type}:</strong> {location.emails}
                    </p>
                  </div>
                ))
              ) : (
                <p>No Email Available</p>
              )}
            </div>
          </div>
        </div> */}

        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiMail />
            </div>
            <div className="inner">
              <h4 className="title">Email Address</h4>
              {contacts.locations.length > 0 ? (
                (() => {
                  // Extract all emails and check if they are the same
                  const allEmails = contacts.locations.map((location) =>
                    location.emails.trim()
                  );
                  const uniqueEmails = [...new Set(allEmails)];

                  if (uniqueEmails.length === 1) {
                    // If there is only one branch, show just the email
                    if (contacts.locations.length === 1) {
                      return (
                        <div>
                          <p>
                             {uniqueEmails[0]}
                          </p>
                        </div>
                      );
                    }
                    // If all branches share the same email, show email and branches
                    const branchTypes = contacts.locations
                      .map((location) => location.type)
                      .join(", ");
                    return (
                      <div>
                        <p>
                          {uniqueEmails[0]}
                        </p>
                        
                      </div>
                    );
                  } else {
                    // Display separate emails with their respective branches
                    return contacts.locations.map((location, index) => (
                      <div key={index} style={{ marginBottom: "1rem" }}>
                        <p>
                          <strong>{location.type}:</strong> {location.emails}
                        </p>
                      </div>
                    ));
                  }
                })()
              ) : (
                <p>No Email Available</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiMail />
            </div>
            <div className="inner">
              <h4 className="title">Our Locations</h4>
              {contacts.locations.length > 0 ? (
                contacts.locations.map((location, index) => (
                  <div key={index} style={{ marginBottom: "1rem" }}>
                    <p>
                      <strong>{location.type}:</strong> {location.address}
                    </p>
                  </div>
                ))
              ) : (
                <p>No Locations Available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          {/* Google Map Section with Overlay */}
          <div
            ref={target}
            style={{ position: "relative" }}
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
          >
            {/* <GoogleMapStyle
              lat={contacts.latitude}
              lng={contacts.longitude}
              zoom={3}
            /> */}

            <img
              src="../images/google_maps.png"
              alt="Location Map"
              style={{
                width: "100%",
                height: "500px",
                borderRadius: "3%",
                objectFit: "cover",
                cursor: "pointer",
                marginBottom: "1rem",
                transition: "transform 0.3s ease",
                boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
              }}
              onMouseOver={(e) => (e.target.style.transform = "scale(1.02)")}
              onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
            />

            <Overlay target={target.current} show={showPopover} placement="top">
              <Popover id="popover-locations" style={popoverStyle}>
                <Popover.Title
                  as="h3"
                  style={{
                    background: "#f8f9fa",
                    borderBottom: "2px solid #eee",
                    padding: "15px",
                    borderRadius: "12px 12px 0 0",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    color: "#2d3436",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FiMapPin style={{ color: "#e74c3c" }} /> Our Locations
                </Popover.Title>
                <Popover.Content style={{ padding: "0" }}>
                  <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {contacts.locations.map((location, index) => (
                      <a
                        key={index}
                        href={location.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        <div
                          style={locationItemStyle}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = "#f8f9fa")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "transparent")
                          }
                        >
                          <span
                            style={{
                              color: "#2d3436",
                              fontWeight: "500",
                              fontSize: "1.3rem",
                            }}
                          >
                            {location.type}
                          </span>
                          <span
                            style={{
                              fontWeight: "500",
                              color: "#636e72",
                              fontSize: "1.2rem",
                            }}
                          >
                            {location.address}
                          </span>
                          <span
                            style={{
                              fontWeight: "600",
                              color: "#636e72",
                              fontSize: "1.2rem",
                            }}
                          >
                            {location.phoneNumbers}
                          </span>
                        </div>
                      </a>
                    ))}
                  </div>
                </Popover.Content>
              </Popover>
            </Overlay>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactOne;
