import React, { useState, useEffect } from "react";
import BlogClassicData from "../../../data/blog/BlogList.json";
import NewsList from "./NewsList";
import { FaSpinner } from "react-icons/fa";
import Axios from "axios";

const alldataBLog = BlogClassicData;
const NewsProp = ({ column, StyleVarProp }) => {
  const [getAllItems] = useState(alldataBLog);

  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);

  const [allNews, setAllNews] = useState([]);

  const getAllNews = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getNews = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_API_URL}/news-events/news`,
        {
          signal,
        }
      );
      if (res.status === 404) {
        setAllNews([]);
      } else {
        setAllNews(res.data.data);
      }
    };
    getNews();
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    getAllNews();
    // setVisibleItems(allNews.slice(0, dataVisibleCount + 6));
  }, []);

  const handleLoadmorebl = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      // if (activeFilter === filters[0].text.toLowerCase()) {
      //     setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      // }else {
      //     setVisibleItems(
      //         getAllItems.filter(
      //           (data) => data.text === activeFilter && data.id <= tempCount
      //         )
      //     );
      // }
    }
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {allNews.map((item) => (
            <div key={item.id} className={column}>
              <NewsList StyleVar={StyleVarProp} data={item} />
            </div>
          ))}
        </div>
      </div>
      {/* <div className="col-lg-12 text-center">
                <div className="rwt-load-more text-center mt--60">
                    <button
                        className="btn btn-default btn-icon"
                        onClick={handleLoadmorebl}
                        disabled={noMorePost ? "disabled" : null}
                    >
                        {noMorePost ? (
                        "No News Here"
                        ) : (
                        <span>
                            View More News 
                            <span className="icon">
                                <FaSpinner />
                            </span>
                        </span>
                        )}
                    </button>
                </div>
            </div> */}
    </>
  );
};

export default NewsProp;
