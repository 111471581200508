import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Axios from "axios";
import UpComingDetailsContent from "../components/upComings/UpComingDetailsContent";

const UpComingDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [upComings, setUpComings] = useState({});

  const getOneUpComing = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getUpComing = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_API_URL}/news-events/${id}`,
        {
          signal,
        }
      );

      if (res.status === 404) {
        setUpComings({});
      } else {
        setUpComings(res.data.data);
      }
    };
    getUpComing();
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    getOneUpComing();
  }, []);

  return (
    <>
      <SEO title="Up Coming || iCAN" />
      <Layout>
        <div className="rn-blog-details-area">
          <UpComingDetailsContent data={upComings} />
        </div>
      </Layout>
    </>
  );
};
export default UpComingDetails;
