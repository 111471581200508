import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

// Custom Marker Component
const Marker = ({ text }) => (
  <div style={{ position: "relative", transform: "translate(-50%, -50%)" }}>
    <img
      src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" // Replace with your custom icon URL
      alt="Marker"
      style={{ height: "40px", width: "40px" }}
    />
    <span
      style={{
        position: "absolute",
        top: "45px",
        left: "50%",
        transform: "translateX(-50%)",
        color: "#000",
        fontSize: "12px",
        background: "#fff",
        padding: "2px 5px",
        borderRadius: "3px",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3)",
      }}
    >
      {text}
    </span>
  </div>
);

class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 23.745219653197715,
      lng: 90.37225183494127,
    },
    zoom: 11,
  };

  render() {
    const { lat, lng, zoom } = this.props;

    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
          center={{ lat, lng }}
          defaultZoom={this.props.zoom}
        >
          <Marker lat lng text="iCanbd" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;
