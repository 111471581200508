import React from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const EventsList = ({ data, StyleVar }) => {
  const truncateTitle = (title, maxLength = 90) => {
    if (!title) return "";
    return title.length > maxLength
      ? `${title.substring(0, maxLength)}...`
      : title;
  };

  const fallbackImage = "/path/to/default/image.jpg";
  const imagePath = data?.image
    ? `${process.env.REACT_APP_BASE_URL}/${data.image.substring(
        data.image.lastIndexOf("public/uploads/")
      )}`
    : fallbackImage;

  return (
    <div
      className={`rn-card ${StyleVar}`}
      style={{display: "flex", flexDirection: "column" }}
    >
      <div className="inner" style={{ height: "100%" }}>
        <div
          className="thumbnail"
          style={{ height: "250px", overflow: "hidden" }}
        >
          <Link
            to={process.env.PUBLIC_URL + `/events-details/${data.id}`}
            className="image"
          >
            <img
              src={imagePath}
              alt={data?.title || "Event Image"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Link>
        </div>
        <div
          className="content"
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 250px)",
          }}
        >
          <h4 className="title" style={{ marginBottom: "15px" }}>
            <Link
              to={process.env.PUBLIC_URL + `/events-details/${data.id}`}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {truncateTitle(data.title)}
            </Link>
          </h4>
          {data?.hasMore && (
            <div className="read-more" style={{ marginTop: "auto" }}>
              <Link
                to={process.env.PUBLIC_URL + `/events-details/${data.id}`}
                className="btn-default"
              >
                Read More
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

EventsList.propTypes = {
  data: PropTypes.object,
};

export default EventsList;
