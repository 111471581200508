import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import TravelAgency from "./pages/TravelAgency";

// Elements import Here

import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";

import Error from "./pages/Error";

// Import Css Here
import "./assets/scss/style.scss";
import NewsGridView from "./components/newsEvents/NewsGridView";
import NewsDetails from "./pages/NewsDetails";
import EventsGridView from "./components/newsEvents/EventsGridView";
import Admission from "./elements/admission/Admission";
import About from "./elements/aboutc/About";
import EventsDetails from "./pages/EventsDetails";
import CourseDetails from "./pages/CourseDetails";
import SuccessHistoryGridView from "./components/successHistory/SuccessHistoryGridView";
import SuccessHistoryDetails from "./pages/SuccessHistoryDetails";
import UpComingGridView from "./components/upComings/UpComingGridView";
import UpComingDetails from "./pages/UpComingDetails";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          {/* This is Home */}
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={TravelAgency}
          />
          {/* News Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/news"}`}
            exact
            component={NewsGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/news-details/:id"}`}
            exact
            component={NewsDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/course-details/:id"}`}
            exact
            component={CourseDetails}
          />
          {/* Events Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/events"}`}
            exact
            component={EventsGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/events-details/:id"}`}
            exact
            component={EventsDetails}
          />

          {/* Up Coming Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/up-comings"}`}
            exact
            component={UpComingGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/up-coming-details/:id"}`}
            exact
            component={UpComingDetails}
          />

          {/* Admission Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/admission"}`}
            exact
            component={Admission}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            exact
            component={Gallery}
          />
          {/* About Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/about-us"}`}
            exact
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            component={Contact}
          />

          {/* Our Success History Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/success-history"}`}
            exact
            component={SuccessHistoryGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/success-history-details/:id"}`}
            exact
            component={SuccessHistoryDetails}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/error"}`}
            exact
            component={Error}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
