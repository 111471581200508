// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import ApiService from "../../apiService/apiservice";

// const Nav = () => {
//   const [courseList, setCourseList] = useState([]);
//   const [isSticky, setIsSticky] = useState(false);

//   // Fetch course data
//   useEffect(() => {
//     ApiService.get(`/courses`)
//       .then((response) => {
//         setCourseList(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching course data:", error);
//       });
//   }, []);

//   // Handle scroll event for sticky nav
//   useEffect(() => {
//     const handleScroll = () => {
//       setIsSticky(window.scrollY > 100); // Change 100 to your preferred scroll threshold
//     };

//     window.addEventListener('scroll', handleScroll);

//     // Cleanup listener
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <ul className={`mainmenu ${!isSticky ? 'nav-not-sticky' : 'nav-sticky'}`}>
//       <li className="has-droupdown">
//         <Link to="/">Home</Link>
//       </li>

//       <li>
//         <Link to="/about-us">About</Link>
//       </li>

//       <li className="has-droupdown">
//         <Link to="#">Courses</Link>
//         <ul className="submenu">
//           {courseList?.length > 0 &&
//             courseList.map((course, index) => (
//               <li key={index}>
//                 <Link to={`/course-details/${course.id}`}>{course.name}</Link>
//               </li>
//             ))}
//         </ul>
//       </li>

//       <li className="has-droupdown">
//         <Link to="#">News & Notices</Link>
//         <ul className="submenu">
//           <li>
//             <Link to="/news">News</Link>
//           </li>
//           <li>
//             <Link to="/events">Events</Link>
//           </li>
//           <li>
//             <Link to="/up-comings">Up Coming</Link>
//           </li>
//         </ul>
//       </li>

//       <li>
//         <Link to="/gallery">Gallery</Link>
//       </li>

//       <li>
//         <Link to="/success-history">Our Success</Link>
//       </li>

//       <li className="has-droupdown">
//         <Link to="/contact">Contact</Link>
//       </li>
//     </ul>
//   );
// };

// export default Nav;


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiService from "../../apiService/apiservice";

const Nav = () => {
  const [courseList, setCourseList] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  // Fetch course data
  useEffect(() => {
    ApiService.get(`/courses`)
      .then((response) => {
        setCourseList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1200);
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle scroll for sticky nav
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ul className={`mainmenu ${isMobileView ? 'mobile-menu' : ''} ${!isSticky ? 'nav-not-sticky' : 'nav-sticky'}`}>
      <li className="has-droupdown">
        <Link to="/">Home</Link>
      </li>

      <li>
        <Link to="/about-us">About</Link>
      </li>

      <li className="has-droupdown">
        <Link to="#">Courses</Link>
        <ul className="submenu">
          {courseList?.length > 0 &&
            courseList.map((course, index) => (
              <li key={index}>
                <Link to={`/course-details/${course.id}`}>{course.name}</Link>
              </li>
            ))}
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="#">News & Notices</Link>
        <ul className="submenu">
          <li>
            <Link to="/news">News</Link>
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
          <li>
            <Link to="/up-comings">Up Coming</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/gallery">Gallery</Link>
      </li>

      <li>
        <Link to="/success-history">Our Success</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};

export default Nav;