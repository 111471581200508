import React, { useState, useEffect } from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceTwo from "../elements/service/ServiceTwo";
import AboutFour from "../elements/about/AboutFour";
import BlogClassicData from "../data/blog/BlogList.json";
import NewsList from "../components/newsEvents/itemProp/NewsList";
import Axios from "axios";
import ApiService from "../apiService/apiservice";

var NewsListData = BlogClassicData.slice(0, 3);

const BannerData = [
  {
    image: "/images/bg/bg-image-7.jpg",
    title: "Elevate and Conquer",
    description:
      "Ignite your potential, conquer challenges, and march towards triumph with unwavering determination and expert guidance.",
  },
  {
    image: "/images/bg/bg-image-8.jpg",
    title: "Rise to Leadership",
    description:
      "Empowering you to soar beyond limits, seize opportunities, and shape your destiny as a distinguished leader.",
  },
];

const TravelAgency = () => {
  const [sliders, setSliders] = useState([]);
  const [latestNews, setLatestNews] = useState([]);

  const [aboutData, setAboutData] = useState({
    title: "",
    content: "",
    section: "",
    image: "",
  });

  useEffect(() => {
    // Replace with your API endpoint
    ApiService.get("/company-infos")
      .then((data) => {
        const { title, content, section, image } = data.data[0];
        setAboutData({ title, content, section, image });
      })
      .catch((error) => {
        console.error("Error fetching about data:", error);
      });
  }, []);

  const getAllSliders = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSliders = async () => {
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/sliders`,
          {
            signal,
          }
        );

        if (res.status === 404) {
          setSliders([]); // No sliders found, set empty array
        } else {
          // Filter sliders where is_active is true
          const activeSliders = res.data.data.filter(
            (slider) => slider.isActive
          );
          setSliders(activeSliders);
        }
      } catch (error) {
        console.error("Error fetching sliders:", error);
        // Optionally handle errors (e.g., set error state or notify user)
      }
    };

    // Call the function to get sliders
    getSliders();

    // Cleanup function to abort the request if necessary
    return () => {
      abortController.abort();
    };
  };

  const getLatestNews = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getLatestNews = async () => {
      const res = await Axios.get(
        `${process.env.REACT_APP_API_URL}/news-events/all`,
        {
          signal,
        }
      );

      if (res.status === 404) {
        setLatestNews([]);
      } else {
        setLatestNews(res.data.data);
      }
    };
    getLatestNews();
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    getAllSliders();
    getLatestNews();
  }, []);

  return (
    <>
      <SEO title="iCAN" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {sliders &&
            sliders.map((data, index) => (
              <div key={index} className="single-slide">
                <div
                  className="height-950 bg_image"
                  data-black-overlay="5"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_BASE_URL
                    }${data.image.substring(
                      data.image.lastIndexOf("public/uploads/")
                    )})`,
                  }}
                >
                  <div className="container">
                    <div className="row row--30 align-items-center">
                      <div className="col-12">
                        <div className="inner text-center">
                          <h1
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: data.imageHeader,
                            }}
                          ></h1>
                          <p
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html: data.imageDescription,
                            }}
                          ></p>
                          <div className="button-group mt--30">
                            <Link className="btn-default" to="/admission">
                              GET ADMISSION
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container">
            <ServiceTwo
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />

        <AboutFour
          title={aboutData.title}
          image={aboutData.image}
          content={aboutData.content}
        />

        {/* Start Elements Area  */}
        {/* TODO: Need this section */}
        {/* <div className="rwt-gallery-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row mt_dec--30 row--15">
                            {PopupData.map((item) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Elements Area  */}

        <Separator />
        <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Latests News & Events"
                  description=""
                />
              </div>
            </div>
            <div className="row row--15">
              {latestNews && latestNews.length > 0
                ? latestNews
                    .filter((item) => item.isRecent)
                    .slice(0, 4)
                    .map((item) => (
                      <div
                        key={item.id}
                        className="col-lg-3 col-md-6 col-sm-12 col-12 mt--30"
                      >
                        <NewsList
                          StyleVar="box-card-style-default"
                          data={item}
                        />
                      </div>
                    ))
                : "No latest news available right now."}
            </div>
          </div>
        </div>

        <Separator />

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default TravelAgency;
