import React from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const EventsList = ({ data, StyleVar }) => {
  const truncateTitle = (title, maxLength = 90) => {
    if (!title) return "";
    if (title.length <= maxLength) return title;
    return `${title.substring(0, maxLength)}...`;
  };

  return (
    <div
      className={`rn-card ${StyleVar}`}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="inner" style={{ height: "100%" }}>
        <div
          className="thumbnail"
          style={{
            height: "250px",
            overflow: "hidden",
          }}
        >
          <Link
            to={process.env.PUBLIC_URL + `/events-details/${data.id}`}
            className="image"
          >
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${
                data &&
                data.image.substring(data.image.lastIndexOf("public/uploads/"))
              }`}
              alt="Event Image"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Link>
        </div>
        <div
          className="content"
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 250px)",
          }}
        >
          <h4 className="title" style={{ marginBottom: "15px" }}>
            <Link
              to={process.env.PUBLIC_URL + `/events-details/${data.id}`}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {truncateTitle(data.title)}
            </Link>
          </h4>
          <div
            className="read-more"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "auto",
              paddingLeft: "0px",
              paddingBottom: "20px",
            }}
          >
            {/* <Link 
                            to={process.env.PUBLIC_URL + `/events-details/${data.id}`}
                            className="btn-default"
                            style={{
                                display: 'inline-block',
                                padding: '8px 20px',
                                backgroundColor: '#059DFF',
                                color: '#fff',
                                borderRadius: '6px',
                                textDecoration: 'none',
                                fontSize: '14px',
                                fontWeight: '500',
                                transition: 'all 0.3s ease'
                            }}
                        >
                            Read More
                        </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

EventsList.propTypes = {
  data: PropTypes.object,
};

export default EventsList;
