import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay, FiZoomIn, FiFolderPlus } from "react-icons/fi";
import ReactPlayer from "react-player";

function GalleryOne({ galleryItem }) {
  const { image, type, category, url, popupLink } = galleryItem;
  const iconHandel = () => {
    if (category === "Video") {
      if (url.toString().match(/youtube/g)) {
        return <FiPlay />;
      } else {
        return <FiZoomIn />;
      }
    } else {
      return <FiZoomIn />;
    }
  };
  const [toggler, setToggler] = useState(false);
  return (
    <div className="rn-gallery icon-center">
      {category === "Image" && (
        <div className="thumbnail">
          <img
            loading="lazy"
            className="radius-small"
            src={`${process.env.REACT_APP_BASE_URL}${
              image && image.substring(image.lastIndexOf("public/uploads/"))
            }`}
            alt="Images"
            style={{
              width: "600px",
              height: "400px",
              backgroundSize: "content", // Ensures image fills the space proportionally
              borderRadius: "10px", // Optional rounded corners
            }}
          />
          {/* <img src={`${image}`} alt="Corporate Image" /> */}
        </div>
      )}

      {/* <div className="video-icon">
                <button className="btn-default rounded-player sm-size" onClick={ () => setToggler(!toggler) }><span>{iconHandel()}</span></button>
            </div> */}
      {/* <FsLightbox 
            toggler={ toggler } 
            sources={url} /> */}
      {category === "Video" && <ReactPlayer url={url} />}
    </div>
  );
}
export default GalleryOne;
